/* TrackStepper.css */

.shopping-basket-icon {
    font-size: 16px;
}

@keyframes upDown {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.ordered-animation {
    animation: upDown 1s infinite;
    color: green;
}

.shipped-animation {
    animation: upDown 1s infinite;
    color: green;
}

.delivered-animation {
    animation: upDown;
    color: blue;
}
