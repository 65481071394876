/* Swiper navigation buttons
.swiper-button-prev, .swiper-button-next {
  color: black;
  background-color:white;
  padding: 2px; 
  border-radius: 50%; 
  width: 20px; 
  height: 20px; 
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.swiper-button-prev:hover, .swiper-button-next:hover {
  background-color: white;
  color:black;
} */





/* //style  */

.responsive-container {
  margin: 0 auto;
  /* padding: 20px 0; */
  margin-top: 58px;
  background-color:white;
  width: 80%; /* Default for desktop */
  /* height: 155px; */
  justify-content: space-between;
}

@media (max-width: 768px) {
  .responsive-container {
      width: 95%; /* For mobile devices */
  }
}