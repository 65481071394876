/* Arrow buttons styles */
.slick-arrow.slick-prev,
.slick-arrow.slick-next {
    width: 30px;  /* Default for mobile */
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  /* Subtle shadow */
    background: rgba(223, 220, 207, 0.65);  /* Transparent background */
    color: #000;
    z-index: 1;
    border-radius: 50%; 
    padding: 0;  /* Reset padding */
}

.slick-prev {
    left: 10px;  /* Spacing on the left */
}

.slick-next {
    right: 10px;  /* Spacing on the right */
}

.slick-prev::before,
.slick-next::before {
    display: none;  /* Hide default arrows */
}

/* Mobile styles */
@media (max-width: 640px) {  /* Small devices */
    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
        width: 30px;
        height: 30px;
        padding-left: 3px; /* Minimal padding */
    }
}

/* Tablet/Desktop styles */
@media (min-width: 1024px) {  
    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
        width: 60px;  /* Larger size */
        height: 60px;
        padding-left: 9px;  /* Padding for desktop */
    }

    .slick-prev {
        left: 20px;
    }

    .slick-next {
        right: 20px;
    }
}

/* Banner section styles */
.banner-section {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    margin-top: 0;  /* No top margin */
}

/* Make sure banner images scale properly on all devices */
.banner-section img {
    width: 100%;  /* Full width */
    height: auto;  /* Maintain aspect ratio */
}

/* Adjust height for different screen sizes */
/* .banner-img { */
    /* height: 11rem;  Small height for mobile */
/* } */

/* @media (min-width: 640px) {  Tablets/Small screens */
    /* .banner-img { */
        /* height: 16rem; */
    /* } */
/* } */

/* @media (min-width: 1024px) {  Larger screens */
   
/* } */

/* Styles for 4K screens */
/* @media (min-width: 1920px) { */
    /* .banner-img { */
        /* height: 28rem;  */
    /* } */
/* } */
