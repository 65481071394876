.sidebar::-webkit-scrollbar {
    width: 5px;
}
   
.sidebar::-webkit-scrollbar-track {
    background-color: #1f2937;
}
   
.sidebar::-webkit-scrollbar-thumb {
    background-color: #4b5563;
}


.sidebar {
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
}

.sidebar.hidden {
    transform: translateX(-100%);
}


@media (max-width: 640px) {
    .sidebar {
        width: 100%;
    }
}
